import { createContext } from "react";

export interface UidContextType {
  uid: string | null;
  pseudo: string | null;
  email:string | null;
  bankroll:number | null;
  risque: number | null;
  Abo: string | null; 
}

export const UidContext = createContext<UidContextType | null>({ uid: null, pseudo: null, email: null, bankroll: null, risque: null, Abo: null});
