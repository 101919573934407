import React, { useState, useEffect } from "react";
import SignUpForm from "./SignUpForm";
import SignInForm from "./SignInForm";

interface LogProps {
  signin: boolean;
  signup: boolean;
}

const Log: React.FC<LogProps> = ({ signin, signup }) => {
  // Initialisation de l'état en fonction des props
  const [signUpModal, setSignUpModal] = useState(signup);
  const [signInModal, setSignInModal] = useState(signin);
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    // Mettre à jour les états si les props changent
    setSignUpModal(signup);
    setSignInModal(signin);

    // Détection de la langue du navigateur
    const userLang = navigator.language;
    
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }
  }, [signin, signup]);

  const handleModals = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const id = e.currentTarget.id;

    if (id === "register") {
      setSignInModal(false);
      setSignUpModal(true);
    } else if (id === "login") {
      setSignInModal(true);
      setSignUpModal(false);
    }
  };

  return (
    <div className="connection-form">
      <div className="form-container">
        <ul>
          <li
            onClick={handleModals}
            id="register"
            className={signUpModal ? "active-btn" : ""}
          >
            {lang === 'fr' ? "S'inscrire" : "Sign Up"}
          </li>
          <li
            onClick={handleModals}
            id="login"
            className={signInModal ? "active-btn" : ""}
          >
            {lang === 'fr' ? "Se connecter" : "Sign In"}
          </li>
        </ul>
        {signUpModal && <SignUpForm />}
        {signInModal && <SignInForm />}
      </div>
    </div>
  );
};

export default Log;
