import { About } from "../components/About";


export const Accueil = () => {
  return (
    <main>
      <h1 className="mt-10 mb-10" style={{ fontSize: '45px', fontFamily: 'Poppins, sans-serif' }}> Bet on Elizaa</h1>
      <About/>

    </main>
  );
};
