import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

const currentDate = new Date();
currentDate.setDate(currentDate.getDate() - 1);
const day = currentDate.getDate();
const month = currentDate.getMonth() + 1;
const formattedCurrentDate = `${day < 10 ? "0" + day : day}-${month < 10 ? "0" + month : month}`;

const currentDate1 = new Date();
currentDate1.setDate(currentDate1.getDate() - 2);
const day1 = currentDate1.getDate();
const month1 = currentDate1.getMonth() + 1;
const formattedNextDate = `${day1 < 10 ? "0" + day1 : day1}-${month1 < 10 ? "0" + month1 : month1}`;

const currentDate2 = new Date();
currentDate2.setDate(currentDate2.getDate() - 3);
const day2 = currentDate2.getDate();
const month2 = currentDate2.getMonth() + 1;
const formattedNextNextDate = `${day2 < 10 ? "0" + day2 : day2}-${month2 < 10 ? "0" + month2 : month2}`;

const dates = [formattedCurrentDate, formattedNextDate, formattedNextNextDate].sort((a, b) => {
  const [dayA, monthA] = a.split("-").map(Number);
  const [dayB, monthB] = b.split("-").map(Number);
  const dateA = new Date(2024, monthA - 1, dayA);
  const dateB = new Date(2024, monthB - 1, dayB);
  return dateB.getTime() - dateA.getTime();
});

const randomizePlayerNames = (winner: string, loser: string, predict: boolean, worthit: boolean, lang: string) => {
  let color = predict ? "#FFEB3B" : "red";
  color = worthit ? color : "white";

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
          alignItems: "center",
          justifyItems: "center",
          textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff", 
        }}
      >
        <span>{winner}</span>
        <i style={{ color: color, fontSize: "33px", marginLeft: "30px", marginRight: "30px" }}>vs</i>
        <span>{loser}</span>
      </div>
      <img
        src={randomizeIssuesPhrase(predict, worthit)}
        alt="Chatbot"
        className="chatbot-image2"
        style={{ width: "60px", height: "60px", display: "block", marginTop: "20px", marginBottom: "-30px" }}
      />
    </div>
  );
};

const randomizeWinningPhrase = (winner: string, loser: string, pred: boolean, probability: number, lang: string) => {
  if (pred) {
    const phrases = lang === 'fr' ? [
      `Victoire de ${winner} avec une estimation de ${probability}%`,
      `${winner} est victorieux avec un pronostic à ${probability}%`,
      `Victoire de ${winner} pronostiquée estimée à ${probability}%`,
      `${winner} gagne le match avec un pronostic de ${probability}%`,
      `${probability}% pronostiqués pour ${winner}.`,
      `${winner} remporte le match avec ${probability}%`,
      `${winner} gagne avec un pronostic de ${probability}%`,
      `Victoire de ${winner} avec une estimation de ${probability}%`,
      `${winner} l'emporte avec un pronostic estimé à ${probability}%`,
      `${winner} sort vainqueur, pronostiqué à ${probability}%`,
      `${winner} s'impose avec une prévision de ${probability}%`,
    ] : [
      `${winner} wins with an estimated probability of ${probability}%`,
      `${winner} is victorious with a prediction of ${probability}%`,
      `Victory for ${winner} predicted with a ${probability}% estimate`,
      `${winner} wins the match with a ${probability}% prediction`,
      `${probability}% predicted for ${winner}.`,
      `${winner} wins the match with ${probability}%`,
      `${winner} wins with a ${probability}% prediction`,
      `Victory for ${winner} with an estimated ${probability}% prediction`,
      `${winner} wins with an estimated prediction of ${probability}%`,
      `${winner} emerges victorious with a ${probability}% prediction`,
      `${winner} prevails with a ${probability}% estimate`,
    ];

    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  } else {
    const proba = 100 - probability;

    const phrases = lang === 'fr' ? [
      `Victoire de ${loser} avec une estimation de ${proba.toFixed(2)}%`,
      `${loser} est victorieux avec un pronostic à ${proba.toFixed(2)}%`,
      `Victoire de ${loser} pronostiquée estimée à ${proba.toFixed(2)}%`,
      `${loser} gagne le match avec un pronostic de ${proba.toFixed(2)}%`,
      `${proba.toFixed(2)}% pronostiqués pour ${loser}.`,
      `${loser} remporte le match avec ${proba.toFixed(2)}%`,
      `${loser} gagne avec un pronostic de ${proba.toFixed(2)}%`,
      `Victoire de ${loser} avec une estimation de ${proba.toFixed(2)}%`,
      `${loser} l'emporte avec un pronostic estimé à ${proba.toFixed(2)}%`,
      `${loser} sort vainqueur, pronostiqué à ${proba.toFixed(2)}%`,
      `${loser} s'impose avec une estimation à ${proba.toFixed(2)}%`,
    ] : [
      `${loser} wins with an estimated probability of ${proba.toFixed(2)}%`,
      `${loser} is victorious with a prediction of ${proba.toFixed(2)}%`,
      `Victory for ${loser} predicted with a ${proba.toFixed(2)}% estimate`,
      `${loser} wins the match with a ${proba.toFixed(2)}% prediction`,
      `${proba.toFixed(2)}% predicted for ${loser}.`,
      `${loser} wins the match with ${proba.toFixed(2)}%`,
      `${loser} wins with a ${proba.toFixed(2)}% prediction`,
      `Victory for ${loser} with an estimated ${proba.toFixed(2)}% prediction`,
      `${loser} wins with an estimated prediction of ${proba.toFixed(2)}%`,
      `${loser} emerges victorious with a ${proba.toFixed(2)}% prediction`,
      `${loser} prevails with a ${proba.toFixed(2)}% estimate`,
    ];

    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  }
};

const randomizeBetingPhrase = (
  bet_size: number,
  worthit: boolean,
  predict: boolean,
  mise: number,
  gain: number,
  lang: string
) => {
  if (predict) {
    const color = worthit ? "#FFEB3B" : "white";
    const evol = (((mise + gain) / mise) * bet_size - bet_size) * 100;

    const phrases = [<p style={{ color: color,   textShadow: "0 0 8px #FFEB3B, 0 0 15px #FFEB3B",  }}> + {evol.toFixed(2)} % </p>];
    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  } else {
    const color = worthit ? "red" : "white";
    const evolv = bet_size * 100;

    const phrases = [<p style={{ color: color }}> - {evolv.toFixed(2)} % </p>];
    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  }
};

const randomizeIssuesPhrase = (pred: boolean, worthit: boolean) => {
  if (worthit) {
    return pred ? "/images/logo3.png" : "/images/logo_wrong.png";
  } else {
    return "/images/logo_neutral.png";
  }
};

const predPhrase = (predict: boolean, worthit: boolean, lang: string) => {
  if (worthit) {
    if (predict) {
      return <p style={{ color: "#FFEB3B" }}>{lang === 'fr' ? "Prédiction correcte !" : "Correct prediction!"}</p>;
    } else {
      return <p style={{ color: "red" }}>{lang === 'fr' ? "Prédiction incorrecte" : "Incorrect prediction"}</p>;
    }
  } else {
    if (predict) {
      return <p style={{ color: "white" }}>{lang === 'fr' ? "Prédiction correcte !" : "Correct prediction!"}</p>;
    } else {
      return <p style={{ color: "white" }}>{lang === 'fr' ? "Prédiction incorrecte" : "Incorrect prediction"}</p>;
    }
  }
};

const randomizeWorthPhrase = (worthit: boolean, lang: string) => {
  if (!worthit) {
    const phrases = lang === 'fr' ? [
      `Ce match n'était pas rentable à long terme.`,
      `Ce n'était pas rentable de miser sur ce match.`,
      `Notre stratégie de paris préconisait de ne pas parier.`,
      `Ce pari n'était pas avantageux sur le long terme.`,
    ] : [
      `This match wasn't profitable in the long term.`,
      `It wasn't profitable to bet on this match.`,
      `Our betting strategy advised against betting.`,
      `This bet wasn't profitable in the long term.`,
    ];

    const randomIndex = Math.floor(Math.random() * phrases.length);
    return phrases[randomIndex];
  } else {
    return null;
  }
};

function formatDateString(dateString: string, lang: string) {
  const [day, month] = dateString.split("-");
  const monthNames = lang === 'fr' ? [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ] : [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${day} ${monthName} 2024`;
}

interface Match {
  id: string;
  date: string;
  tournoi: string;
  round: string;
  surface: number;
  dnn: {
    vainqueur: string;
    perdant: string;
    proba: number;
    worthit: boolean;
    roc: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    predict: boolean;
  };
  voting: {
    vainqueur: string;
    perdant: string;
    proba: number;
    worthit: boolean;
    roc: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    predict: boolean;
  };
  xgbc: {
    vainqueur: string;
    perdant: string;
    proba: number;
    worthit: boolean;
    roc: boolean;
    shrink: { mise: number; gain: number; bet_size: number };
    clemz: { mise: number; gain: number };
    predict: boolean;
  };
}

export const Historique: React.FC = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [lang, setLang] = useState('fr');
  const [matches, setMatches] = useState<{ date: string; matches: Match[] }[]>([]);
  const [filteredMatches, setFilteredMatches] = useState<{ date: string; matches: Match[] }[]>([]);
  const [activeButton, setActiveButton] = useState<string>("worthit");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }

    const fetchData = async (date: string) => {
      try {
        const response = await fetch(`/results/${date}.json`);
        const data = await response.json();
        return { date, matches: data.matche };
      } catch (error) {
        
        return { date, matches: [] };
      }
    };

    const loadAllData = async () => {
      const promises = dates.map((date) => fetchData(date));
      const results = await Promise.all(promises);

      const newMatches = results.sort((a, b) => {
        const [dayA, monthA] = a.date.split("-").map(Number);
        const [dayB, monthB] = b.date.split("-").map(Number);
        const dateA = new Date(2024, monthA - 1, dayA);
        const dateB = new Date(2024, monthB - 1, dayB);
        return dateB.getTime() - dateA.getTime();
      });

      setMatches(newMatches);
      setFilteredMatches(filterMatches(newMatches, activeButton === "worthit"));
    };

    loadAllData();
  }, [activeButton]);

  const showWorthItMatches = () => {
    const worthItMatches = filterMatches(matches, true);
    setFilteredMatches(worthItMatches);
    setActiveButton("worthit");
  };

  const showAllMatches = () => {
    const allMatches = filterMatches(matches, false);
    setFilteredMatches(allMatches);
    setActiveButton("all");
  };

  const filterMatches = (
    matches: { date: string; matches: Match[] }[],
    worthit: boolean
  ) => {
    if (worthit) {
      return matches
        .map((matchGroup) => ({
          date: matchGroup.date,
          matches: matchGroup.matches.filter((match) => match.voting.worthit),
        }))
        .filter((matchGroup) => matchGroup.matches.length > 0);
    }
    return matches;
  };

  return (
    <section className="mb-10 mt-10">
      <div
        style={{
          background: "#0C2340",
          padding: isMobile ? "10px" : "20px",
          textAlign: "center",
          borderRadius: "20px",
          margin: isMobile ? "10px" : "20px 150px",
          textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff",  // camelCase pour text-shadow
          border: "2px solid #00d8ff",  // camelCase pour border-bottom
          fontFamily: "'Orbitron', sans-serif",  // camelCase pour font-family
          boxShadow: "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)",
          
        }}
      >
        <p
          className="risk-label"
          style={{
            color: "white",
            fontSize: isMobile ? "20px" : "30px",
          }}
        >
          {lang === 'fr' ? "Historique" : "History"}
        </p>
        <br />

        <div
          className="button-containedr"
          style={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "center",
            
            
          }}
        >
          <button
            className={`risk-button ${activeButton === "worthit" ? "active" : ""}`}
            onClick={showWorthItMatches}
          >
            {lang === 'fr' ? "Matches rentables" : "Profitable Matches"}
          </button>
          <button
            className={`risk-button ${activeButton === "all" ? "active" : ""}`}
            onClick={showAllMatches}
          >
            {lang === 'fr' ? "Tous les matches" : "All Matches"}
          </button>
          
        </div>
      </div>
      <div style={{fontSize:'19px',color:'black'}}><p>“One machine can do the work of fifty ordinary men. No machine can do the work of one extraordinary man.”
      — Elbert Hubbard</p></div>
      

      {filteredMatches.length === 0 ? (
        <p
          style={{
            color: "white",
            textAlign: "center",
            fontSize: isMobile ? "20px" : "25px",
            marginTop: "50px",
            
          }}
        >
          {lang === 'fr' ? "Pas de matchs " : "No recent matchs"}
        </p>
      ) : (
        filteredMatches.map((matchGroup, index) => (
          <div key={index} className="mb-20" style={{ textAlign: isMobile ? "center" : "left" }}>
            <p
              className="mt-10 mb-0"
              style={{
                fontWeight: "bold",
                fontSize: isMobile ? "20px" : "30px",
                textAlign: "center",
              }}
            >
              {formatDateString(matchGroup.date, lang)}
            </p>
            <br />
            {Object.entries(
              matchGroup.matches.reduce((acc, match) => {
                const key = `${match.tournoi}-${match.round}`;
                if (!acc[key]) {
                  acc[key] = [];
                }
                acc[key].push(match);
                return acc;
              }, {} as { [key: string]: Match[] })
            ).map(([key, matches]) => (
              <div key={key} style={{ marginLeft: isMobile ? "0" : "10px" }}>
                <p
                  className="mb-20 mt-20"
                  style={{
                    fontWeight: "bold",
                    fontSize: isMobile ? "18px" : "26px",
                    textAlign: isMobile ? "center" : "left",
                  }}
                >
                  {matches[0].tournoi} - {matches[0].round}
                </p>
                <ul style={{ listStyleType: "none", padding: 2 }}>
                  {matches.map((match, matchIndex) => (
                    <li
                      key={matchIndex}
                      style={{
                        position: "relative",
                        marginBottom: isMobile ? "40px" : "80px",
                        backgroundColor: "#0C2340",
                        color: "white",
                        borderTopLeftRadius: "20px",
                        borderBottomLeftRadius: "20px",
                        padding: "10px",
                        marginLeft: isMobile ? "auto" : "50px",
                        marginRight: isMobile ? "auto" : "150px",
                        width: isMobile ? "90%" : "auto",
                        overflow: "visible",
                        borderTop:'2px solid #00d8ff',
                        borderBottom:'2px solid #00d8ff'
                      }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          right: "-9px",
                          bottom: "0",
                          width: isMobile ? "6px" : "10px",
                          backgroundColor: "#0C2340",
                          maskImage:
                            "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10'%3E%3Cpath fill='%23fff' d='M10 0L0 0 0 10z'/%3E%3C/svg%3E\")",
                          maskRepeat: "repeat-y",
                          maskSize: "9px 9px",
                          maskPosition: "right",
                          
                          
                        }}
                      ></div>
                      <div
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "0",
                          width: isMobile ? "60px" : "80px",
                          height: isMobile ? "100px" : "150px",
                          backgroundColor: "black",
                          borderRadius: "0 80px 80px 0",
                          transform: "translateY(-50%)",
                          
                          
                        }}
                      ></div>
                      <p
                        style={{
                          textAlign: "center",
                          marginBottom: "30px",
                          fontSize: isMobile ? "18px" : "25px",
                          marginRight: "20px",
                          marginLeft: isMobile ? "0" : "120px",
                          fontWeight: "bold",
                          
                        }}
                      >
                        {randomizePlayerNames(
                          match.voting.vainqueur,
                          match.voting.perdant,
                          match.voting.predict,
                          match.voting.worthit,
                          lang
                        )}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: isMobile ? "18px" : "25px",
                          marginRight: "20px",
                          marginLeft: isMobile ? "50px" : "120px",
                          fontWeight: "bold",
                          marginBottom: "30px",
                        }}
                      >
                        {predPhrase(match.voting.predict, match.voting.worthit, lang)}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: isMobile ? "16px" : "22px",
                          marginRight: "20px",
                          marginLeft: isMobile ? "50px" : "120px",
                        }}
                      >
                        {randomizeWinningPhrase(
                          match.voting.vainqueur,
                          match.voting.perdant,
                          match.voting.predict,
                          match.voting.proba,
                          lang
                        )}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: isMobile ? "16px" : "22px",
                          marginRight: "20px",
                          marginLeft: isMobile ? "50px" : "120px",
                        }}
                      >
                        {randomizeWorthPhrase(match.voting.worthit, lang)}
                      </p>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: isMobile ? "18px" : "25px",
                          marginRight: "20px",
                          marginLeft: isMobile ? "50px" : "120px",
                          marginTop: "30px",
                          fontWeight: "bold",
                        }}
                      >
                        {randomizeBetingPhrase(
                          match.voting.shrink.bet_size,
                          match.voting.worthit,
                          match.voting.predict,
                          match.voting.shrink.mise,
                          match.voting.shrink.gain,
                          lang
                        )}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        ))
      )}
    </section>
  );
};
