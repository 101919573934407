import React, { useContext, useState, useEffect } from "react";
import { UidContext } from "../components/AppContext";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";

const stripePromise = loadStripe(
  "pk_live_51PfI5fRtpmJcb5L9auPAtjw6szSEUL4lrMKywJzsh2NTNnjjAZDyhV5Rv9lryY7OkIbxnf2ad6Z7hdrHzKMP92C400U20a5CSG"
);

export const Introduction: React.FC = () => {
  const uid = useContext(UidContext);
  const pseudo = uid?.pseudo;
  const uidControl = uid?.uid;
  const abo = uid?.Abo;
  const navigate = useNavigate();

  const [lang, setLang] = useState("fr");

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== "fr-FR" && userLang !== "fr") {
      setLang("en");
    }
  }, []);

  const handleCheckout = async () => {
    const stripe = await stripePromise;

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/user/create-checkout-session`,
        {},
        { withCredentials: true }
      );
      const session = res.data;
      const result = await stripe?.redirectToCheckout({
        sessionId: session.id,
      });
      if (result?.error) {
      }
    } catch (err) {}
  };

  const handleNavigateToConnexion = () => {
    navigate("/connexion");
  };

  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          textAlign: "left",
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Orbitron",
          marginTop: "-10px",
          marginBottom: "10px",
          marginLeft: "0px",
        }}
      >
        {lang === "fr"
          ? "Don't be broke, break the book"
          : "Don't be broke, break the book"}
      </div>

      <section
        style={{
          padding: "20px 0",
          margin: "20px 0",
          position: "relative",
        }}
      >
        {/* Barre supérieure avec effet néon */}
        <div
          style={{
            borderTop: "12px solid #FFD700", // Magenta néon pour la barre supérieure
            boxShadow: "0 0 15px #FFD700, 0 0 30px #FFD700, 0 0 45px #FFD700", // Effet néon magenta
            borderRadius: "10px", // Arrondi les coins pour un look plus doux
            marginTop: "-20px",
            marginBottom: "10px",
          }}
        />
        <div
          style={{
            fontSize: "20px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px 20px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        >
          {!uidControl ? (
            <div>
              <p
                style={{
                  marginBottom: "5px",
                  lineHeight: "1.5",
                }}
              >
                {lang === "fr"
                  ? "Eliza est une intelligence artificielle spécialisée dans la prédiction des matchs de tennis et propose une stratégie de paris visant à optimiser les gains sur le long terme. Inscrivez-vous gratuitement pour découvrir certains de ses pronostics !"
                  : "Eliza is an AI specialized in predicting tennis matches and offers a betting strategy aimed at optimizing long-term gains. Sign up for free to discover some of her predictions!"}
              </p>

              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div
                  onClick={handleNavigateToConnexion}
                  style={{
                    display: "inline-block",
                    backgroundColor: "#001f3f",
                    color: "#FFEB3B",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    textDecoration: "none",
                    border: "2px solid #00d8ff",
                    boxShadow:
                      "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)",
                    fontWeight: "bold",
                    fontSize: "16px",
                    transition:
                      "background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    textShadow: "0 0 8px #00d8ff, 0 0 15px #00d8ff", // camelCase pour textShadow
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#FFEB3B";
                    e.currentTarget.style.color = "#001f3f";
                    e.currentTarget.style.boxShadow =
                      "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"; // Effet néon jaune
                    e.currentTarget.style.border = "none";

                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#001f3f";
                    e.currentTarget.style.color = "#FFEB3B";
                    e.currentTarget.style.boxShadow = 
                    "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)"; // Retire l'effet néon quand la souris quitte
                    e.currentTarget.style.border = "2px solid #00d8ff";
                    
                    
                  }}
                >
                  {lang === "fr" ? "S'inscrire" : "Sign up"}
                </div>
              </div>
            </div>
          ) : !abo ? (
            <div>
              <p
                style={{
                  marginBottom: "5px",
                  lineHeight: "1.5",
                }}
              >
                {lang === "fr"
                  ? "Augmentez votre capital de départ en fonction de ce dernier. Devenez membre Premium pour ne pas manquer les conseils sur les matchs les plus intéressants !"
                  : "Increase your starting capital accordingly. Become a Premium member to avoid missing out on advice on the most interesting matches!"}
              </p>

              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <div
                  onClick={handleCheckout}
                  style={{
                    display: "inline-block",
                    backgroundColor: "#001f3f",
                    color: "#FFEB3B",
                    padding: "10px 20px",
                    borderRadius: "10px",
                    textDecoration: "none",
                    fontWeight: "bold",
                    fontSize: "16px",
                    transition:
                      "background-color 0.3s ease, color 0.3s ease, box-shadow 0.3s ease",
                    cursor: "pointer",
                    boxShadow: "none", // Aucun effet de lueur par défaut
                  }}
                  onMouseOver={(e) => {
                    e.currentTarget.style.backgroundColor = "#FFEB3B";
                    e.currentTarget.style.color = "#001f3f";
                    e.currentTarget.style.boxShadow =
                      "0 0 15px #FFEB3B, 0 0 30px #FFEB3B, 0 0 45px #FFEB3B"; // Effet néon jaune
                    e.currentTarget.style.border = "none";

                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.style.backgroundColor = "#001f3f";
                    e.currentTarget.style.color = "#FFEB3B";
                    e.currentTarget.style.boxShadow = 
                    "0 4px 15px rgba(0, 216, 255, 0.75), 0 0 30px rgba(0, 216, 255, 0.6)"; // Retire l'effet néon quand la souris quitte
                    e.currentTarget.style.border = "2px solid #00d8ff";
                  }}
                >
                  {lang === "fr" ? "Devenir membre" : "Become a member"}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p
                style={{
                  marginBottom: "5px",
                  lineHeight: "1.5",
                }}
              >
                {lang === "fr"
                  ? `Vous êtes actuellement membre premium ${pseudo} ! Vous avez la possibilité de découvrir quels sont nos conseils pour se positionner sur les matches les plus intéressants contenant l'icône d'Eliza.`
                  : `You are currently a premium member, ${pseudo}! You can discover our tips on how to position yourself on the most interesting matches featuring Eliza's icon.`}
                <br /> <br />
                <img
                  src="/images/logo2.png"
                  alt="ROC Indicator"
                  style={{
                    display: "inline-block",
                    width: "80px",
                    height: "80px",
                    marginRight: "5px",
                    marginLeft: "20px",
                  }}
                />
              </p>
            </div>
          )}
        </div>
        <div
          style={{
            borderBottom: "12px solid #FF3131", // Magenta néon pour la barre inférieure
            marginTop: "20px",
            marginBottom: "-50px",
            boxShadow: "0 0 15px #FF3131, 0 0 30px #FF3131, 0 0 45px #FF3131", // Effet néon magenta pour la barre inférieure
            borderRadius: "10px",
          }}
        />
      </section>
    </div>
  );
};
