import React, { useEffect, useState, CSSProperties } from "react";

interface StatsData {
  start_bankroll: number;
  start_day: string;
  day_evolv: number;
  worthit: {
    wins: number;
    losses: number;
    winrate: number;
    shrink_bankroll: number;
    shrink_ROC_bankroll: number;
    clemz_bankroll: number;
  };
  all: {
    wins: number;
    losses: number;
    winrate: number;
    shrink_bankroll: number;
    shrink_ROC_bankroll: number;
    clemz_bankroll: number;
  };
}

export const Stats: React.FC = () => {
  const [stats, setStats] = useState<StatsData>();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [isVerySmallScreen, setIsVerySmallScreen] = useState(window.innerWidth <= 480);
  const [todayDate, setTodayDate] = useState("");
  const [language, setLanguage] = useState<"fr" | "en">("fr");

  const translations = {
    fr: {
      predictionsSince: "Prédictions depuis le",
      correct: "Correctes",
      incorrect: "Incorrectes",
      precision: "Précision",
      capitalEvolution: "Évolution de votre capital",
    },
    en: {
      predictionsSince: "Predictions since",
      correct: "Correct",
      incorrect: "Incorrect",
      precision: "Precision",
      capitalEvolution: "Capital Evolution",
    },
  };

  const getFormattedDate = (): string => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    return `${day}-${month}`;
  };

  useEffect(() => {
    const detectLanguage = () => {
      const userLang = navigator.language;
      setLanguage(userLang.startsWith("fr") ? "fr" : "en");
    };

    const fetchData = async () => {
      try {
        const response = await fetch("stats.json");
        const data = await response.json();
        setStats(data.voting);
      } catch (error) {
        console.error("Erreur lors de la récupération des statistiques:", error);
      }
    };

    detectLanguage();
    fetchData();
    setTodayDate(getFormattedDate());

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsVerySmallScreen(window.innerWidth <= 480);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const monthEvolv = stats
    ? (stats.worthit.shrink_bankroll / stats.start_bankroll) * 100 - 100
    : undefined;

  const getStyleForValue = (value: number): CSSProperties => ({
    color: value < 0 ? "red" : value > 0 ? "#FFEB3B" : "inherit",
  });

  const getStyleForWinrate = (winrate: number): CSSProperties => ({
    color: winrate > 50 ? "#FFEB3B" : "red",
  });

  const formatValue = (value: number): string => {
    return `${value > 0 ? "+" : ""}${value.toFixed(2)} %`;
  };

  const styles = {
    section: { marginBottom: "5px" },
    dashboard: {
      display: "flex",
      alignItems: "flex-start",
      flexWrap: isSmallScreen ? "wrap" : "nowrap",
    } as CSSProperties,
    widgets: {
      display: "flex",
      flexDirection: "column" as const,
      justifyContent: "space-between",
      flex: "1 1 auto",
      maxWidth: "100%",
      marginBottom: isSmallScreen ? "20px" : "0",
    },
    widget: { marginBottom: "30px" },
    h2: {
      fontWeight: "bold" as const,
      fontSize: isVerySmallScreen ? "24px" : "30px",
    },
    widgetContent: {
      fontSize: isVerySmallScreen ? "18px" : "22px",
    },
    span: {
      width: isVerySmallScreen ? "150px" : "200px",
      display: "inline-block",
    },
    imageContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "flex-end",
      flex: "1 1 auto",
      marginLeft: isSmallScreen ? "0" : "60px",
    },
    chatbotImage: {
      width: "96%",
      height: "auto",
      alignSelf: "flex-end",
    },
  };

  const { predictionsSince, correct, incorrect, precision, capitalEvolution } =
    translations[language];

  return (
    <section style={styles.section}>
      {stats && (
        <div>
          <div style={styles.dashboard} className="dashboard">
            <div style={styles.widgets} className="widgets">
              <div style={styles.widget} className="widget">
                <h2 style={styles.h2}>
                  {predictionsSince} {stats.start_day}
                </h2>

                <div style={styles.widgetContent} className="widget-content">
                  <p>
                    <span style={styles.span}>{correct} :</span>
                    {stats.all.wins}
                  </p>

                  <p>
                    <span style={styles.span}>{incorrect} :</span>
                    {stats.all.losses}
                  </p>
                  <p>
                    <span style={styles.span}>{precision} :</span>
                    <span style={getStyleForWinrate(stats.all.winrate)}>
                      {stats.all.winrate} %
                    </span>
                  </p>
                </div>
              </div>
              <div style={styles.widget} className="widget">
                <h2 style={styles.h2}>{capitalEvolution}</h2>
                <p style={{ fontSize: "22px" }}>
                  {language === "fr"
                    ? "Si vous suivez les conseils d'Eliza depuis le  "
                    : "If you follow Eliza's advice at"}
                </p>

                <div style={styles.widgetContent} className="widget-content">
                  <p>
                    <span style={styles.span}>{todayDate} :</span>
                    <span style={getStyleForValue(stats.day_evolv)}>
                      {formatValue(stats.day_evolv)}
                    </span>
                  </p>
                  <p>
                    <span style={styles.span}>{stats.start_day} :</span>
                    <span
                      style={monthEvolv ? getStyleForValue(monthEvolv) : {}}
                    >
                      {monthEvolv && formatValue(monthEvolv)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div style={styles.imageContainer} className="image-container">
              <img
                src="/images/queen.png"
                alt="Chatbot"
                style={styles.chatbotImage}
                className="chatbot-image"
              />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};
