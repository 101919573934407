import React from "react";
import axios from "axios";
import cookie from "js-cookie";

const Logout = () => {
  const removeCookie = (key: string) => {
    if (window !== undefined) {
      cookie.remove(key, { expires: 1 }); // Supprime le cookie immédiatement
    }
  };

  const handleLogout = async () => {
    try {
      await axios({
        method: "GET",
        url: `${process.env.REACT_APP_API_URL}api/user/logout`,
        withCredentials: true,
      }).then(() => removeCookie("jwt"));
 
      window.location.href = "/";
    } catch (err) {
 
      window.location.href = "/";
    }
    /* window.location.href = "/";  */
  };

  return (
    <button
      onClick={handleLogout}
      style={{
        display: "inline-block",
        backgroundColor: "#0C2340",
        color: "#FFEB3B",
        padding: "10px 20px",
        borderRadius: "10px",
        textDecoration: "none",
        fontWeight: "bold",
        fontSize: "16px",
        transition: "background-color 0.3s ease, color 0.3s ease",
        fontFamily: "Poppins, sans-serif",
        cursor: "pointer",
        marginLeft: "10px", // Espace entre les autres boutons et celui-ci
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = "#FFEB3B";
        e.currentTarget.style.color = "#0C2340";
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = "#0C2340";
        e.currentTarget.style.color = "#FFEB3B";
      }}
    >
      Se déconnecter 
    </button>
  );
};

export default Logout;
