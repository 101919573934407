import React, { useEffect } from "react";
import { Introduction } from "./Introduction";
import { Prevention } from "./Prevention";

export const Abo = () => {

  return (
    <div>test</div>
   
  );
};
