import React, { useState, useContext, useEffect } from 'react';
import { UidContext } from '../components/AppContext';
import { useLocation } from 'react-router-dom';
import { Prevention } from '../components/Prevention';
import { Introduction } from '../components/Introduction';
import axios from 'axios';

const ChangePassword = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const message = urlParams.get('message');
  const id = urlParams.get('id');

  const uid = useContext(UidContext);
  const uidControl = uid?.uid;
  const [oldPassword, setOldPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [errors, setErrors] = useState<{ oldPassword: string; newPassword: string; confirmPassword: string; general: string }>({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    general: ''
  });
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }
  }, []);

  const handleChangePassword = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    setErrors({ oldPassword: '', newPassword: '', confirmPassword: '', general: '' });

    if (newPassword !== confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: lang === 'fr' ? 'Les nouveaux mots de passe ne correspondent pas' : 'The new passwords do not match',
      }));
      setIsLoading(false);
      return;
    }

    const requestData = uidControl ? { oldPassword, newPassword, uidControl } : { newPassword, id };
    axios({
      method: 'POST',
      url: `${process.env.REACT_APP_API_URL}api/user/change-password`,
      withCredentials: true,
      data: requestData,
    })
      .then((res) => {
        if (res.data.errors) {
          setErrors((prevErrors) => ({ ...prevErrors, ...res.data.errors }));
        } else {
          setSuccessMessage(lang === 'fr' ? 'Mot de passe changé avec succès !' : 'Password changed successfully!');
        }
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const serverErrors = error.response.data.errors || {};
          const mappedErrors = {
            oldPassword: serverErrors.oldPassword || '',
            newPassword: serverErrors.password || '',
            confirmPassword: serverErrors.confirmPassword || '',
            general: serverErrors.general || '',
          };
          setErrors((prevErrors) => ({ ...prevErrors, ...mappedErrors }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: lang === 'fr' ? 'Erreur lors du changement de mot de passe' : 'Error changing password',
          }));
        }
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div style={{ marginBottom: '-300px' }}>
        <Introduction />
      </div>
      <div className="profil-page">
        <div className="log-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <div className="connection-form" style={{ fontSize: '19px', width: '400px' }}>
            {successMessage ? (
              <p className="success">{successMessage}</p>
            ) : (
              <form onSubmit={handleChangePassword} id="change-password-form">
                {uidControl && (
                  <>
                    <label htmlFor="oldPassword">{lang === 'fr' ? 'Ancien mot de passe' : 'Old Password'}</label>
                    <br />
                    <input
                      type="password"
                      name="oldPassword"
                      id="oldPassword"
                      onChange={(e) => setOldPassword(e.target.value)}
                      value={oldPassword}
                      autoComplete="new-password"
                    />
                    <br />
                    {errors.oldPassword && <div className="password error">{errors.oldPassword}</div>}
                    <br />
                  </>
                )}
                <label htmlFor="newPassword">{lang === 'fr' ? 'Nouveau mot de passe' : 'New Password'}</label>
                <br />
                <input
                  type="password"
                  name="newPassword"
                  id="newPassword"
                  onChange={(e) => setNewPassword(e.target.value)}
                  value={newPassword}
                  autoComplete="new-password"
                />
                <br />
                {errors.newPassword && <div className="password error">{errors.newPassword}</div>}
                <br />
                <label htmlFor="confirmPassword">{lang === 'fr' ? 'Confirmer le nouveau mot de passe' : 'Confirm New Password'}</label>
                <br />
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  value={confirmPassword}
                />
                <br />
                {errors.confirmPassword && <div className="password-confirm error">{errors.confirmPassword}</div>}
                <br />
                {errors.general && <div className="general error">{errors.general}</div>}
                <input type="submit" value={lang === 'fr' ? 'Changer le mot de passe' : 'Change Password'} disabled={isLoading} />
              </form>
            )}
          </div>
        </div>
      </div>
      <div style={{ marginTop: '-200px' }}>
        <Prevention />
      </div>
    </div>
  );
};

export default ChangePassword;
