import React, { useEffect, useState } from "react";
import axios from "axios";
import { Routes, Route, NavLink } from "react-router-dom";
import { Accueil } from "./screens/Accueil";
import { Home } from "./screens/Home";
import { Connexion } from "./screens/Connexion";
import { Aides } from "./screens/Aides";
import { Eliza } from "./screens/Eliza";
import { Explications } from "./components/Explications";
import { Introduction } from "./components/Introduction";
import { Terms } from "./components/Terms";
import { Privacy } from "./components/Privacy";
import { Abo } from "./components/Abo";






import { UidContext, UidContextType } from "./components/AppContext";
import Navbar from "./components/Navbar";

import ChangePassword from './components/ChangePassword';

import "./styles/index.scss";
import "./App.css";

interface User {
  id: string;
  pseudo: string;
  email: string;
  password: string;
  date: Date;
  Abo: string | null;
  bankroll: number | null;
  risque: number;
}

function App(): JSX.Element {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}jwtid`, {
          withCredentials: true,
        });

        const userData: User = res.data;
        setUser(userData);
      } catch (err) {
        console.error(
          "Erreur lors de la récupération des données utilisateur:",
          err
        );
      }
    };

    fetchUserData();
  }, []);

  //  les valeurs du contexte
  const uidContextValue: UidContextType = {
    uid: user ? user.id : null,
    pseudo: user ? user.pseudo : null,
    email: user ? user.email : null,
    bankroll: user ? user.bankroll : null,
    risque: user ? user.risque : null,
    Abo: user ? user.Abo : null,
  };



  return (
    <UidContext.Provider value={uidContextValue}>
      <div className="app-container">
        <header className="mb-5">
          <div className="header-content flex justify-between items-center mb-5">
          <h1 className="font-extrabold flex items-center" style={{ fontSize: '46px' }}>
              <img className="mr-1"
                style={{ width: '12%', height: 'auto', marginTop: '-10px', marginBottom: '-10px'  }}
                src="/images/logo3.png"
                alt="eli"
              />
              <NavLink
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "bold",
                  color: isActive ? " #FFEB3B" : "inherit",
                })}
                to="/eliza"
              >
                Eliza
              </NavLink>
              <NavLink
                className="mr-3"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                  color: isActive ? " #FFEB3B" : "inherit",
                })}
                to="/"
              >
                bet
              </NavLink>
            </h1>
            <Navbar></Navbar>
          </div>
        </header>
        <div className="container mt-10">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/aides" element={<Aides />} />
            <Route path="/connexion" element={<Connexion />} />
            <Route path="/accueil" element={<Accueil />} />
            <Route path="/eliza" element={<Eliza />} />
            <Route path="/explications" element={<Explications />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/abonnement" element={<Abo />} />
            

            

          </Routes>
        </div>
        <footer className="mt-5">
          <div className="footer-content flex justify-between items-center max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <p>© 2024 Elizabet. Tous droits réservés.</p>
            <nav className="flex justify-end space-x-4">
              <NavLink
                className="text-white"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/privacy"
              >
                Politique de confidentialité
              </NavLink>
              <NavLink
                className="text-white"
                style={({ isActive }) => ({
                  fontWeight: isActive ? "bold" : "normal",
                })}
                to="/terms"
              >
                Conditions d'utilisation
              </NavLink>
            </nav>
          </div>
        </footer>
      </div>
    </UidContext.Provider>
  );
}

export default App;
