import React from "react";
import { Bet } from "../components/Bet";
import { Prevention } from "../components/Prevention";
import { Introduction } from "../components/Introduction";

export const Home: React.FC = () => {
  return (
    <main
      className="flex flex-col items-center w-full" // Changez ici width à 100%
     
    >
      <div className="w-full flex justify-center">
        <Introduction />
      </div>

      <div className="w-full flex justify-center">
        <Bet />
      </div>
      <div className="w-full flex justify-center">
        <Prevention />
      </div>
    </main>
  );
};
