import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { UidContext } from "./AppContext";
import Logout from "./Log/Logout";

const Navbar = () => {
  const uid = useContext(UidContext);
  const uidControl = uid?.uid;
  const pseudo = uid?.pseudo;

  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }
  }, []);

  return (
    <nav className="flex flex-wrap justify-start">
      <NavLink
        to="/"
        style={({ isActive }) => ({
          marginRight: "15px",
          fontWeight: isActive ? "bold" : "normal",
          color: isActive ? "#FFEB3B" : "white",
          textDecoration: "none",
          transition: "color 0.3s, fontWeight 0.3s",
          fontSize: "20px",
        })}
      >
        {lang === 'fr' ? "Accueil" : "Home"}
      </NavLink>

      <NavLink
        to="/eliza"
        style={({ isActive }) => ({
          marginRight: "15px",
          fontWeight: isActive ? "bold" : "normal",
          color: isActive ? "#FFEB3B" : "white",
          textDecoration: "none",
          transition: "color 0.3s, fontWeight 0.3s",
          fontSize: "20px",
        })}
      >
        {lang === 'fr' ? "Résultats" : "Results"}
      </NavLink>

      <NavLink
        to="/explications"
        style={({ isActive }) => ({
          marginRight: "15px",
          fontWeight: isActive ? "bold" : "normal",
          color: isActive ? "#FFEB3B" : "white",
          textDecoration: "none",
          transition: "color 0.3s, fontWeight 0.3s",
          fontSize: "20px",
        })}
      >
        {lang === 'fr' ? "Modèle" : "Model"}
      </NavLink>

      {uidControl ? (
        <ul>
          <NavLink
            style={({ isActive }) => ({
              marginRight: "15px",
              fontWeight: isActive ? "bold" : "normal",
              color: isActive ? "#FFEB3B" : "white",
              textDecoration: "none",
              transition: "color 0.3s, fontWeight 0.3s",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
            })}
            to="/connexion"
          >
            <div className="mr-2">{pseudo}</div>
          </NavLink>
   
        </ul>
      ) : (
        <ul>
          <NavLink
            style={({ isActive }) => ({
              marginRight: "15px",
              fontWeight: isActive ? "bold" : "normal",
              color: isActive ? "#FFEB3B" : "white",
              textDecoration: "none",
              transition: "color 0.3s, fontWeight 0.3s",
              fontSize: "20px",
            })}
            to="/connexion"
          >
            {lang === 'fr' ? "Connexion" : "Login"}
          </NavLink>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
