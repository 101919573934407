import React, { useState, useEffect } from "react";
import axios from "axios";
import SignInForm from "./SignInForm";
import { useLocation } from 'react-router-dom';

const SignUpForm = () => {
  const location = useLocation();
  const [formSubmit, setFormSubmit] = useState(false);
  const [pseudo, setPseudo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [controlPassword, setControlPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [validationMessage, setValidationMessage] = useState<string | null>(null);
  const [errors, setErrors] = useState({ pseudo: "", email: "", password: "", controlPassword: "", general: "" });
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }

    const urlParams = new URLSearchParams(location.search);
    const message = urlParams.get('message');

    if (message === 'success') {
      setValidationMessage(lang === 'fr' ? "Votre compte a été validé avec succès ! Veuillez vous connecter" : "Your account has been successfully validated! Please log in.");
    } else if (message === 'failure') {
      setValidationMessage(lang === 'fr' ? "Erreur lors de la validation de votre compte" : "Error validating your account");
    } else {
      setValidationMessage(null);
    }
  }, [location.search]);

  const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const terms = document.getElementById("terms") as HTMLInputElement | null;

    // Réinitialiser les erreurs
    setErrors({ pseudo: "", email: "", password: "", controlPassword: "", general: "" });

    if (password !== controlPassword || !terms?.checked) {
      if (password !== controlPassword) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          controlPassword: lang === 'fr' ? "Les mots de passe ne correspondent pas" : "Passwords do not match",
        }));
      }
      if (!terms?.checked) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          general: lang === 'fr' ? "Veuillez valider les conditions générales" : "Please accept the terms and conditions",
        }));
      }
    } else {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_URL}api/user/register`,
          {
            pseudo,
            email,
            password,
          }
        );

        if (res.data.errors) {
          setErrors(res.data.errors);
        } else {
          setFormSubmit(true);
          setEmailSent(true); // Mettre à jour l'état pour indiquer que l'email a été envoyé
        }
      } catch (error: any) {
        if (error.response && error.response.data) {
          if (error.response.data.errors) {
            setErrors(error.response.data.errors);
          } else if (error.response.data.message) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general: error.response.data.message,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general: lang === 'fr' ? "Erreur lors de l'inscription" : "Error during registration",
            }));
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general: lang === 'fr' ? "Erreur lors de l'inscription" : "Error during registration",
          }));
        }
      }
    }
  };

  return (
    <>
      {validationMessage ? (
        <h4 className={validationMessage.includes(lang === 'fr' ? "succès" : "success") ? "success" : "error"}>
          {validationMessage}
        </h4>
      ) : formSubmit ? (
        <>
          {emailSent ? (
            <h4 className="success">
              {lang === 'fr' ? (
                <>Inscription réussie! <br />Veuillez vérifier votre email pour valider votre compte:<br />{email}</>
              ) : (
                <>Registration successful! <br />Please check your email to validate your account:<br />{email}</>
              )}
            </h4>
          ) : (
            <SignInForm />
          )}
        </>
      ) : (
        <form action="" onSubmit={handleRegister} id="sign-up-form">
          <label htmlFor="pseudo">{lang === 'fr' ? "Pseudo" : "Username"}</label>
          <br />
          <input
            type="text"
            name="pseudo"
            id="pseudo"
            onChange={(e) => setPseudo(e.target.value)}
            value={pseudo}
          />
          <br />
          {errors.pseudo && <div className="pseudo error">{errors.pseudo}</div>}
          <br />
          <label htmlFor="email">{lang === 'fr' ? "Email" : "Email"}</label>
          <br />
          <input
            type="email"
            name="email"
            id="email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <br />
          {errors.email && <div className="email error">{errors.email}</div>}
          <br />
          <label htmlFor="password">{lang === 'fr' ? "Mot de passe" : "Password"}</label>
          <br />
          <input
            type="password"
            name="password"
            id="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <br />
          {errors.password && <div className="password error">{errors.password}</div>}
          <br />
          <label htmlFor="password-confirm">{lang === 'fr' ? "Confirmer mot de passe" : "Confirm Password"}</label>
          <br />
          <input
            type="password"
            name="password-confirm"
            id="password-confirm"
            onChange={(e) => setControlPassword(e.target.value)}
            value={controlPassword}
          />
          <br />
          {errors.controlPassword && <div className="password-confirm error">{errors.controlPassword}</div>}
          <br />
          <input type="checkbox" id="terms"></input>
          <label htmlFor="terms">
            {lang === 'fr' ? "J'accepte les" : "I accept the"}{" "}
            <a href="/privacy" target="_blank" rel="noopener noreferrer">
              {lang === 'fr' ? "conditions générales" : "terms and conditions"}
            </a>
          </label>
          <div className="terms error"></div>
          <br />
          {errors.general && <div className="general error">{errors.general}</div>}
          <input type="submit" value={lang === 'fr' ? "Valider inscription" : "Register"} />
        </form>
      )}
    </>
  );
};

export default SignUpForm;
