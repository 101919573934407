import React from 'react';

export const Aides = () => {
  return (
    <section className="mb-5">
      <h1
        className="mt-10 mb-10"
        style={{ fontSize: '45px', fontFamily: 'Poppins, sans-serif' }}
      >
        Aides
      </h1>

      <div className="mt-5" style={{ display: 'flex', marginLeft: '40px', fontSize: '25px' }}>
        <div style={{ flex: '1' }}>
          <p
            style={{
              textAlign: 'left',
              marginTop: '40px',
              fontSize: '27px',
              marginBottom: '40px',
            }}
          >
            Elizabeth offre des prédictions sur les matchs de tennis, incluant les probabilités de victoire, et recommande une stratégie de paris aux utilisateurs en tenant compte de leur capital de départ et des cotes proposées par les bookmakers.
          </p>
          <div className="ml-8">
            <h1
              className="mt-20 mb-8 text-center font-bold"
              style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}
            >
              Capital
            </h1>
            <p className="mb-8">
              Cela représente la somme d'argent que vous allouez aux paris sportifs. Il ne constitue en aucun cas la totalité de vos fonds !<br />
              Ne misez que ce que vous pouvez vous permettre de perdre et considérez ce capital comme de l'argent que vous avez déjà dépensé.
            </p>

            <h1
              className="mt-8 mb-8 text-center font-bold"
              style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}
            >
              Stratégies
            </h1>
            <p className="mb-8">
              Nous offrons une stratégie conçue pour accroître le capital initial sur le long terme.<br />
              Il est recommandé de mettre à jour cette somme mensuellement afin de tirer pleinement parti de cette stratégie. Les mises seront déterminées par le montant de votre capital. Si vous augmentez ce dernier après une journée de gains, les pertes potentielles des jours suivants pourraient être plus importantes que prévu initialement. En revanche, si vous diminuez le capital après une journée de pertes, les gains potentiels seront réduits le jour suivant. C'est pourquoi il est important de rester cohérent en ce qui concerne votre capital, afin d'optimiser les mises.
            </p>

            <h1
              className="mt-8 mb-8 text-center font-bold"
              style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}
            >
              Côtes
            </h1>
            <p className="mb-8">
              Après avoir fourni les pronostics, nous proposons une mise optimisée en fonction du capital, mais aussi de la cote du bookmaker (affectueusement appelé booky), que vous trouverez sur les sites de paris sportifs. Il est recommandé d'en visiter plusieurs afin de maximiser ces gains potentiels.
            </p>

            <h1
              className="mt-8 mb-8 text-center font-bold"
              style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}
            >
              Pronostics
            </h1>
            <p className="mb-8">
              La cote du bookmaker permet de déterminer les gains potentiels ainsi que les pronostics implicites fournis par les bookmakers, ce qui influence nos stratégies. Ces dernières sont calculées en fonction de nos pronostics, du capital ainsi que de la cote du bookmaker.
            </p>

            <h1
              className="mt-8 mb-8 text-center font-bold"
              style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}
            >
              Risques
            </h1>
            <p style={{ marginBottom: '20px' }}>
              Un pari est considéré comme risqué (plus que la normale) lorsqu'il n'y a pas de consensus sur l'issue d'un match. Plusieurs IA travaillent ensemble pour fournir les meilleures prédictions possibles, en utilisant le principe de la sagesse des foules. Si une trop grande discordance apparaît entre les résultats, le pari est alors jugé très incertain.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
