import React, { useState, useEffect } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const SignInForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({
    email: "",
    password: "",
    general: "",
  }); // État pour stocker les erreurs
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    // Détection de la langue du navigateur
    const userLang = navigator.language;
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }
  }, []);

  const handleForgotPassword = () => {
    if (!email) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: lang === 'fr'
          ? "Veuillez entrer votre email pour réinitialiser le mot de passe."
          : "Please enter your email to reset your password.",
      }));
      return;
    }

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}api/user/forgot-password`,
      withCredentials: true,
      data: {
        email,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          setErrors(res.data.errors);
        } else {
          alert(
            lang === 'fr'
              ? `Un email de réinitialisation de mot de passe a été envoyé à votre adresse mail ${email}.`
              : `A password reset email has been sent to your email address ${email}.`
          );
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.errors) {
            setErrors(err.response.data.errors);
          } else if (err.response.data.message) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general: err.response.data.message,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general:
                lang === 'fr'
                  ? "Erreur lors de l'envoi de l'email de réinitialisation."
                  : "Error sending password reset email.",
            }));
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general:
              lang === 'fr'
                ? "Erreur lors de l'envoi de l'email de réinitialisation."
                : "Error sending password reset email.",
          }));
        }
      });
  };

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Réinitialiser les erreurs
    setErrors({ email: "", password: "", general: "" });

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}api/user/login`,
      withCredentials: true,
      data: {
        email,
        password,
      },
    })
      .then((res) => {
        if (res.data.errors) {
          setErrors(res.data.errors);
        } else {
          window.location.href = "/"; // Utilisez window.location.href pour la redirection
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.data.errors) {
            setErrors(err.response.data.errors);
          } else if (err.response.data.message) {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general: err.response.data.message,
            }));
          } else {
            setErrors((prevErrors) => ({
              ...prevErrors,
              general:
                lang === 'fr'
                  ? "Erreur lors de la connexion"
                  : "Error during login",
            }));
          }
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            general:
              lang === 'fr'
                ? "Erreur lors de la connexion"
                : "Error during login",
          }));
        }
      });
  };

  return (
    <form onSubmit={handleLogin} id="sign-in-form">
      <label htmlFor="email">
        {lang === 'fr' ? "Email" : "Email"}
      </label>
      <br />
      <input
        type="text"
        name="email"
        id="email"
        onChange={(e) => setEmail(e.target.value)}
        value={email}
      />
      <br />
      {errors.email && <div className="email error">{errors.email}</div>}
      <br />
      <label htmlFor="password">
        {lang === 'fr' ? "Mot de passe" : "Password"}
      </label>
      <br />
      <input
        type="password"
        name="password"
        id="password"
        onChange={(e) => setPassword(e.target.value)}
        value={password}
      />
      <br />
      {errors.password && (
        <div className="password error">{errors.password}</div>
      )}
      {errors.password && (
        <div>
          <a href="#" className="forgot-password-link" onClick={handleForgotPassword}>
            {lang === 'fr' ? "Mot de passe oublié ?" : "Forgot password?"}
          </a>
        </div>
      )}
      <br />
      {errors.general && (
        <div className="general error">{errors.general}</div>
      )}
      <input type="submit" value={lang === 'fr' ? "Se connecter" : "Sign In"} />
    </form>
  );
};

export default SignInForm;
