import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

export const About = () => {
  const [lang, setLang] = useState('fr');

  useEffect(() => {
    const userLang = navigator.language;
    console.log('Langue détectée par le navigateur:', userLang);
    if (userLang !== 'fr-FR' && userLang !== 'fr') {
      setLang('en');
    }

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML';
    script.async = true;
    document.head.appendChild(script);
   
  }, []);

  return (
    <section className="mb-5">
      {lang === 'fr' ? (
        <p style={{ fontSize: '25px', marginLeft: '40px' }} className="mt-5">
          Entraîné dddd sur une base de données comprenant plus de 100 000 matchs, 
          notre modèle prend en compte les performances passées des joueurs couplés à des composantes environnementales.
          <br/>
          <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Performances </h1>

          La courbe ROC (Receiver Operating Characteristic) ci-dessous est un outil d'évaluation permettant de mesurer la performance d'un modèle de classification binaire 
          comme celui d'Eliza.
          <br/>
          <br/> 
          <img src="/images/Figure_1.png" alt="Eliza" style={{ }} />
          <br/> 
          Cette courbe représente la capacité du modèle à discriminer les matches correctement prédits des matches mal prédits : 
          <br/>
          <br/>
          <ul style={{ marginLeft: '40px', listStyleType: 'disc', fontSize: '0.9em' }}>
          <li>
          L'axe des ordonnées représente le taux de vrais positifs (aussi appelé sensibilité) : il s'agit du pourcentage de matches gagnés correctement classés parmi tous les matches réellement gagnés.
          </li>
          <br/>
          <li>
          L'axe des abscisses représente le taux de faux positifs : il s'agit du pourcentage de matches perdus incorrectement classés comme gagnés parmi tous les matches perdus.
          </li>
        </ul>
        <br/>   

          Plus la courbe se rapproche du coin supérieur gauche du graphique, Meilleure est la performance."
          <br/>
          <br/>
          Sur notre courbe ROC, l'AUC (Area Under the Curve), l'aire sous la courbe est de 0.76. Ce résultat indique
          que si vous prenez deux matchs, un gagné et l'autre perdu, le modèle les classera correctement dans 76 % des cas en moyenne. 
          Plus l'AUC est proche de 1, meilleure est la performance du modèle. Une AUC de 0.5 indiquerait une prédiction aléatoire.
      
          <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Stratégies de paris </h1>
          Nous proposons également une stratégie de paris revisitée visant à optimiser les gains à long terme tout en minimisant les 
          risques de pertes immédiates. Nous avons adapté et revisité la formule de Kelly en fonction des paramètres de nos modèles et de leurs résultats. 
          <br/> 
          <br/>
          
          
          <div>
        <p>Formule de Kelly :</p>
        
        <div style={{ textAlign: 'center', fontSize: '1.2em' }}>
          {String.raw`\( f^* = \frac{bp - q}{b} \)`}
        </div>
        
        <br/>
        
        <ul style={{ marginLeft: '40px', listStyleType: 'disc', fontSize: '0.8em' }}>
          <li>
            \( f^* \) : fraction du capital à miser
          </li>
          <li>
            \( p \) : probabilité de gagner
          </li>
          <li>
            \( q \) : probabilité de perdre
          </li>
          <li>
            \( b \) : côte du bookmaker
          </li>
        </ul>      
      </div>
      <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Attention </h1>
      <p>
          Eliza n'est pas conseillère en investissement et ne garantit pas la richesse à ses utilisateurs. 
          <br/>
          Ne pariez que ce que vous pouvez vous permettre de perdre.
          <br/>
          <br/>
           L'addiction aux jeux d'argent peut entraîner des conséquences graves, telles que l'endettement, l'isolement social, voire la dépression.
          <br/>
          <br/>
          Si vous pensez avoir un problème de jeu, il est important de demander de l'aide. Vous pouvez contacter <a href="https://www.joueurs-info-service.fr/" target="_blank" rel="noopener noreferrer">Joueurs Info Service </a>
           au 09 74 75 13 13 pour obtenir des conseils gratuits. Il s'agit d'un appel non surtaxé et confidentiel.
        </p>

        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <a href="https://www.joueurs-info-service.fr/" target="_blank" rel="noopener noreferrer" style={{ 
            display: 'inline-block', 
            backgroundColor: '#f44336', 
            color: 'white', 
            padding: '10px 20px', 
            borderRadius: '5px', 
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '16px'
          }}>
            Obtenir de l'aide maintenant
          </a>
        </div>
      </p>
      ) : (
        <p style={{ fontSize: '25px', marginLeft: '40px' }} className="mt-5">
          Trained on a database of over 100,000 matches, 
          our model takes into account players' past performances coupled with environmental factors.
          <br/>
          <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Performance </h1>

          The ROC curve (Receiver Operating Characteristic) below is an evaluation tool used to measure the performance of a binary classification model 
          like Eliza's.
          <br/>
          <br/> 
          <img src="/images/Figure_1.png" alt="Eliza" style={{ }} />
          <br/> 
          This curve represents the model's ability to discriminate correctly predicted matches from incorrectly predicted ones:
          <br/>
          <br/>
          <ul style={{ marginLeft: '40px', listStyleType: 'disc', fontSize: '0.9em' }}>
          <li>
          The y-axis represents the true positive rate (also called sensitivity): this is the percentage of correctly classified won matches among all actually won matches.
          </li>
          <br/>
          <li>
          The x-axis represents the false positive rate: this is the percentage of lost matches incorrectly classified as won among all lost matches.
          </li>
        </ul>
        <br/>   

          The closer the curve is to the top left corner of the graph, the better the performance.
          <br/>
          <br/>
          On our ROC curve, the AUC (Area Under the Curve) is 0.76. This result indicates
          that if you take two matches, one won and the other lost, the model will correctly classify them 76% of the time on average. 
          The closer the AUC is to 1, the better the model's performance. An AUC of 0.5 would indicate a random prediction.
      
          <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Betting Strategies </h1>
          We also offer a revised betting strategy aimed at optimizing long-term gains while minimizing 
          the risk of immediate losses. We have adapted and revised the Kelly formula based on our models' parameters and results. 
          <br/> 
          <br/>
          
          
          <div>
        <p>Kelly Formula:</p>
        
        <div style={{ textAlign: 'center', fontSize: '1.2em' }}>
          {String.raw`\( f^* = \frac{bp - q}{b} \)`}
        </div>
        
        <br/>
        
        <ul style={{ marginLeft: '40px', listStyleType: 'disc', fontSize: '0.8em' }}>
          <li>
            \( f^* \) : fraction of capital to bet
          </li>
          <li>
            \( p \) : probability of winning
          </li>
          <li>
            \( q \) : probability of losing
          </li>
          <li>
            \( b \) : bookmaker's odds
          </li>
        </ul>      
      </div>
      <h1 className="mt-8 mb-8 text-center font-bold" style={{ fontSize: '33px', fontFamily: 'Poppins, sans-serif' }}>Warning </h1>
      <p>
          Eliza is not an investment advisor and does not guarantee wealth to its users. 
          <br/>
          Only bet what you can afford to lose.
          <br/>
          <br/>
           Gambling addiction can lead to serious consequences, such as debt, social isolation, or even depression.
          <br/>
          <br/>
          If you think you have a gambling problem, it's important to seek help. You can contact <a href="https://www.joueurs-info-service.fr/" target="_blank" rel="noopener noreferrer">Joueurs Info Service </a>
           at 09 74 75 13 13 for free advice. This is a free and confidential call.
        </p>

        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <a href="https://www.joueurs-info-service.fr/" target="_blank" rel="noopener noreferrer" style={{ 
            display: 'inline-block', 
            backgroundColor: '#f44336', 
            color: 'white', 
            padding: '10px 20px', 
            borderRadius: '5px', 
            textDecoration: 'none',
            fontWeight: 'bold',
            fontSize: '16px'
          }}>
            Get help now
          </a>
        </div>
      </p>
      )}
    </section>
  );
};
